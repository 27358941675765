.icon-fa-button {
  border-color: transparent;
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  // box-shadow: #eee 0px 0px 2px;
  padding: 3px 5px;
  transition: box-shadow 0.3s ease;
  &:hover {
    cursor: pointer;
    background-color: #868e9633 !important;
  }
  &:active {
    cursor: pointer;
    background-color: #868e9633;
    transform: translate(0px, 2px) !important;
  }
}
