@media print {
  body * {
    visibility: hidden;
  }

  .letter-container,
  .letter-container * {
    visibility: visible;
  }

  .letter-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 8.5in;
    /* height: 11in; */
    padding: 4px;
    box-sizing: border-box;
    background: white;
  }

  .no-print {
    display: none;
  }
}
.letter-container {
  width: 100%;
  max-width: 800px;
  margin: auto;
  padding: 16px;
  background-color: white;
}
.letter-container-wrapper {
  width: 100%;
  max-width: 800px;
  margin: auto;
  padding: 24px;
  color: black;
}
.avoid-break {
  break-inside: avoid;
}
