$fa-font-path: '../webfonts';

// @import './assets/fontawesome/scss/fontawesome.scss';
// @import './assets/fontawesome/scss/brands.scss';
// @import './assets/fontawesome/scss/solid.scss';
// @import './assets/fontawesome/scss/light.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

$--app-shell-header-z-index: 100;

.clickable {
  cursor: pointer;
}

// override bootstrap
.form-label {
  color: #5d6e82;
  font-size: 13px;
  line-height: 13px;
}

.card-body {
  padding: 24px !important;
}

// Fulfill order / Order process
.order-log-item {
  position: relative;
  padding: 1px 0;
}

.order-log-item:first-of-type:after {
  height: 50%;
}

.order-log-item:last-of-type:after {
  height: 50%;
  top: 0;
}

.order-log-item:after {
  content: '';
  bottom: 0px;
  position: absolute;
  left: 19px;
  height: 100%;
  border-left: 1px dashed #b6c2d2;
}

// Overlay modal

.overlay .modal-content {
  background-color: transparent;
  align-items: center;
  border: none;
  box-shadow: none;
}

.one-line-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.mantine-Modal-root,
.mantine-Drawer-root {
  section,
  .section {
    padding: 0 !important;
  }
}
.navbar-vertical {
  z-index: 1 !important;
}
.navbar-top {
  z-index: 1 !important;
}
.mantine-Menu-dropdown {
  z-index: 1021 !important;
}
.mantine-Popover-dropdown {
  z-index: 1055 !important;
}
.delete-warning-modal {
  position: absolute;
  z-index: 1056 !important;
}
.comment-input {
  textarea {
    border: 0;
  }
}
.mantine-Grid-col {
  .tox-tinymce-wrapper.tox-tinymce__wrapper--on-focus {
    box-shadow: var(--falcon-box-shadow-inset), 0 0 0 0 black !important;
  }
}
