.promotion-product-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 70px;
  &:hover {
    background-color: #fff2f9;
  }
}
.promotion-product-item-disabled {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 70px;
  background-color: #fafafa;
}
.custom-scrollbar-section {
  &::-webkit-scrollbar {
    width: 1px; /* Adjust the width as needed */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff; /* Adjust the color as needed */
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff; /* Adjust the color as needed */
  }
}
